import React, { Component } from "react"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"
import BackgroundImage from "gatsby-background-image"

import Layout from "../../components/layout"
import CTABanner from "../../components/common/ctaBanner/ctaBanner"
import homeBannerImg2 from "../../../assets/home-banner-img2.png"
import * as styles from "./ironcap-x.module.scss"
import chat from "../../../assets/support-chat.png"
import FeaturesHighlights from "../../components/sections/featuresHighlights/featuresHighlights"
import HowIronCAPXWorks from "../../components/sections/howIronCAPXWorks/howIronCAPXWorks"
import Pricing from "../../components/sections/pricing/pricing"
import ProgressiveImage from "react-progressive-image-loading"
import banner2bg from "../../../assets/home-banner2.png"
import banner2bgmin from "../../../assets/home-banner2-min.png"
import { Helmet } from "react-helmet"
import { TM } from "../../components/common/typography/trademark"
import { LearnMoreTagline } from "../../components/common/typography/banner-tagline"
class IronCAPX extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ie: false,
      modalOpen: false,
      isBrowser: false,
    }
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      let isIE = /*@cc_on!@*/ false || !!document.documentMode
      this.setState({
        ie: isIE,
        isBrowser: true,
      })
    }
  }
  openChat = () => {
    window.open(
      "http://www.imoncall.com/ironcapchat",
      <>
        IronCAP
        <TM /> Live Chat
      </>,
      "width=500,height=600"
    )
  }
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Post-Quantum Cybersecurity | Email Encryption</title>
          <meta
            name="keywords"
            content="Post-quantum email encryption, Post-quantum email
            signature, Post-quantum file encryption, Post-quantum file
            signature, Quantum-safe email encryption, Quantum-safe
            email signature, Quantum-safe file encryption"
          />
          <meta
            name="description"
            content="Our email/file encryption is FREE, end-to-end with digital
            signatures to provide bulletproof encryption. Our digital
            signature feature eliminates phishing scams. The world’s
            most proven quantum-safe data encryption, backed by
            cryptographers and scientists."
          />
          <link rel="canonical" href="https://ironcap.ca" />
        </Helmet>
        <div id={styles.carouselContainer}>
          <ProgressiveImage
            preview={banner2bgmin}
            src={banner2bg}
            transitionTime={500}
            transitionFunction="ease"
            render={(src, style) => (
              <div
                className={styles.carouselGraphic2}
                style={Object.assign(style, { backgroundImage: `url(${src})` })}
              >
                <div className={styles.carouselText}>
                  <h4>Post-Quantum</h4>
                  <h3>
                    IronCAP X<TM />
                  </h3>
                  <p>
                    IronCAP X<TM /> is the world's first end-to-end email/file
                    encryption and digital signing system that utilizes the
                    IronCAP
                    <TM /> Toolkits as the underlying cryptographic system to
                    ensure safety against cyber attacks from not only the
                    conventional world of computers today but also against
                    future attacks from quantum computers. IronCAP X<TM />{" "}
                    simply integrates with your everyday email client such as
                    Outlook, Gmail, Yahoo, etc. to ensure zero learning curve.
                  </p>
                  <div className={styles.bannerButtonsContainer}>
                    <a href="#" target="_blank" onClick={this.openChat}>
                      <button className={styles.bannerButton}>
                        <img src={chat} alt="Launch pre-sales chat" />
                        <div className={styles.bannerButtonText}>
                          <p>Live Chat</p>
                          <p>Pre-Sales</p>
                        </div>
                      </button>
                    </a>

                    <button
                      className={styles.bannerButton}
                      onClick={() => this.setState({ modalOpen: true })}
                    >
                      <img src={chat} alt="Play Why IronCAP X Video" />
                      <div className={styles.bannerButtonText}>
                        <p>Video</p>
                        <p>Why ICX</p>
                      </div>
                    </button>
                  </div>
                </div>
                <img
                  className={styles.carouselImg}
                  src={homeBannerImg2}
                  alt="Second slide"
                  style={{
                    width: "35%",
                    maxWidth: "530px",
                    marginRight: "14.5%",
                  }}
                />
              </div>
            )}
          />
        </div>
        <FeaturesHighlights />
        <HowIronCAPXWorks />
        <Pricing IE={this.state.ie} />

        <CTABanner
          tagline={LearnMoreTagline}
          CTA="video"
          CTADetail="how video"
        />
        {this.state.isBrowser && (
          <ModalVideo
            channel="youtube"
            isOpen={this.state.modalOpen}
            videoId="Kc6TGzEXs74"
            onClose={() => this.setState({ modalOpen: false })}
            style={{ position: "absolute" }}
          />
        )}
      </Layout>
    )
  }
}

export default IronCAPX
