import React from "react"
import * as styles from "./featuresHighlightsCard.module.scss"

// TODO: find way to dynamically import photos
const FeaturesHighlightsCard = ({ description, title, img }) => (
  <div className={styles.card}>
    <div className={styles.imageContainer}>
      <img src={img} alt="Vertical Applications icon" />
    </div>
    <h3>{title}</h3>
    <p> {description}</p>
  </div>
)

export default FeaturesHighlightsCard
