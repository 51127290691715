import React from "react"
import * as styles from "./pricing.module.scss"
import faq from "../../../../assets/faq.png"
import PricingColumns from "../../common/pricingColumns/pricingColumns"
import { Link } from "gatsby"
import ProgressiveImage from "react-progressive-image-loading"

import pricingbg from "../../../../assets/home-banner2.png"
import pricingbgmin from "../../../../assets/home-banner2-min.png"
import { TM } from "../../common/typography/trademark"

const columnItems = [
  {
    heading: "Personal Usage",
    price: "$0.00",
    textArray: [
      {
        text: (
          <>
            Protected by IronCAP
            <TM /> Cryptography
          </>
        ),
      },
      {
        text: "Send/Receive Encrypted Emails",
      },
      {
        text: "Encrypt Sensitive Files",
      },
      {
        text: "Digitally Sign your Emails/Files",
      },
      {
        text: "Free Unlimited Personal Usage",
      },
      {
        text: "No Credit Card Required",
      },
      {
        text: "One User Per Account",
      },
    ],
  },

  {
    heading: "Business Usage",
    price: "$99.95",
    tagline: "per user/yr",
    textArray: [
      {
        text: (
          <>
            Protected by IronCAP
            <TM /> Cryptography
          </>
        ),
      },
      {
        text: "Send/Receive Encrypted Emails",
      },
      {
        text: "Encrypt Sensitive Files",
      },
      {
        text: "Digitally Sign your Emails/Files",
      },
      {
        text: "Unlimited Business Usage",
      },
      {
        text: "Central Business Administration",
      },
      {
        text: "Multiple Users per Account",
      },
    ],
  },
  {
    heading: (
      <>
        IronCAP
        <TM /> Key
      </>
    ),
    price: "$199.95",
    textArray: [
      {
        text: "Optional One-Time Purchase",
      },
      {
        text: "Add Physical Authentication",
      },
      {
        text: "Compatible with FREE/Business Users",
      },
      {
        text: (
          <>
            Ultimate Security With Cryptographic Processing Inside the IronCAP
            <TM /> Key Outside the Operating System
          </>
        ),
      },
    ],
  },
]

const Pricing = ({ IE }) => (
  <ProgressiveImage
    preview={pricingbgmin}
    src={pricingbg}
    transitionTime={500}
    transitionFunction="ease"
    render={(src, style) => (
      <div
        className={styles.pricingSection}
        style={Object.assign(style, { backgroundImage: `url(${src})` })}
      >
        <h2>Pricing and Comparison</h2>
        <div className={styles.cardsContainer}>
          <PricingColumns columnItems={columnItems} IE={IE} />
        </div>
        <Link to="/ironcap-x/support">
          <div className={styles.faqContainer}>
            <img src={faq} alt="FAQ" />
            <span>Frequently Asked Questions</span>
          </div>
        </Link>
      </div>
    )}
  />
)

export default Pricing
