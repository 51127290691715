import React from "react"

import * as styles from "./pricingColumns.module.scss"

const PricingColumns = ({ columnItems, IE }) => (
  <div className={IE ? styles.contentGridIE : styles.contentGrid}>
    {columnItems.map(column => {
      return (
        <div key={column.heading}>
          <div className={styles.columnHeader}>
            <p>{column.heading}</p>
            <p>{column.price}</p>
            {column.heading.toString().includes("Business") ? (
              <p
                className={styles.columnTagline}
                dangerouslySetInnerHTML={{ __html: column.tagline }}
              />
            ) : null}
          </div>
          <div className={styles.columnItemContainer}>
            {column.textArray.map(textItem => {
              return (
                <div className={styles.columnItem}>
                  <p>{textItem.text}</p>
                  <div />
                </div>
              )
            })}
          </div>
        </div>
      )
    })}
  </div>
)

export default PricingColumns
