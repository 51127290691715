import React, { Component } from "react"
import * as styles from "./howIronCAPXWorks.module.scss"
import ModalVideo from "react-modal-video"
import { TM } from "../../common/typography/trademark"

class HowIronCAPXWorks extends Component {
  constructor(props) {
    super()
    this.state = {
      isOpen: false,
      isBrowser: false,
    }
    this.openModal = this.openModal.bind(this)
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      this.setState({
        isBrowser: true,
      })
    }
    if (!document.getElementById("wistia_script")) {
      var wistiaScript = document.createElement("script")
      wistiaScript.id = "wistia_script"
      wistiaScript.type = "text/javascript"
      wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js"
      wistiaScript.async = true
      wistiaScript.popover = true
      document.body.appendChild(wistiaScript)
    }
  }

  componentWillUnmount() {
    this.handle && this.handle.remove()
  }

  openModal() {
    this.setState({ isOpen: true })
  }

  render() {
    return (
      <div className={styles.whyIronCap}>
        <div className={styles.whyVideo}>
          {this.state.isBrowser && (
            <ModalVideo
              channel="youtube"
              isOpen={this.state.isOpen}
              videoId="iKSD8MYShsY"
              onClose={() => this.setState({ isOpen: false })}
            />
          )}
        </div>
        <div className={styles.bannerContainer}>
          <button className={styles.playWhyButton} onClick={this.openModal} />
          <div className={styles.bannerContainerText}>
            <h3>
              How IronCAP X<TM /> Works
            </h3>
            <p>
              IronCAP X<TM /> simply integrates with your everyday email clients
              such as Outlook and Gmail. We only charge for business usage. For
              personal usage, simply sign up a FREE account and install IronCAP
              X<TM /> on your computer where you run your email clients and
              begin sending encrypted emails to your recipients as well as
              enjoying other features.
            </p>
            <p>
              Use IronCAP X<TM /> to ensure the safety of your data, not only
              against cyber attacks from computers today but also from quantum
              computers in the future.
            </p>
          </div>
        </div>
      </div>
    )
  }
}
export default HowIronCAPXWorks
