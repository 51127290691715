import React from "react"
import FeaturesHighlightsCard from "./featuresHighlightsCard"
import * as styles from "./featuresHighlights.module.scss"
import thumb from "../../../../assets/thumb.png"
import emailencryption from "../../../../assets/email-encryption.png"
import emailauthenticity from "../../../../assets/email-authenticity.png"
import fileencryption from "../../../../assets/file-encryption.png"
import documentauthenticity from "../../../../assets/document-authenticity.png"
import key from "../../../../assets/key.png"
import { TM } from "../../common/typography/trademark"

const featuresArray = [
  {
    title: "Zero Learning Curve",
    description: (
      <>
        IronCAP X<TM /> tightly integrates with your everyday email client such
        as Outlook, Gmail, Yahoo, etc. to ensure a zero learning curve.
      </>
    ),
    img: thumb,
  },
  {
    title: "End-to-End Email Encryption",
    description: (
      <>
        Unlike some secure email systems that store your emails and require your
        recipients to read from their servers, IronCAP X<TM /> is a direct
        end-to-end encryption with no central storage point that's vulnerable to
        a cyber attack.
      </>
    ),
    img: emailencryption,
  },
  {
    title: "Email Authenticity",
    description: (
      <>
        IronCAP
        <TM /> allows you to digitally sign your emails to ensure your
        recipients know that they are from you rather than from a bogus hacker.
      </>
    ),
    img: emailauthenticity,
  },
  {
    title: "General File Encryption",
    description: (
      <>
        IronCAP X<TM /> allows you to encrypt your sensitive files such as
        personal finances, etc. to safeguard against cyber attack not only today
        but also in the future of quantum computing.
      </>
    ),
    img: fileencryption,
  },
  {
    title: "Digital Document Authenticity",
    description: (
      <>
        IronCAP X<TM /> allows you to sign your digital files to prove their
        authenticity that they are the original true copies from you rather than
        from a bogus hacker. This email/file signature feature will protect your
        data.
      </>
    ),
    img: documentauthenticity,
  },
  {
    title: (
      <>
        Optional IronCAP
        <TM /> Key
      </>
    ),
    description: (
      <>
        IronCAP
        <TM /> Key is an optional USB stick device enhancing your email/file
        security to the max. No one including top national security agents can
        decrypt your encrypted emails/files without your password plus your
        IronCAP
        <TM /> Key physically inserted into your computer.
      </>
    ),
    img: key,
  },
]

const FeaturesHighlights = () => (
  <div className={styles.featuresHighlightsSection}>
    <h2>Features Highlights</h2>
    <div className={styles.cardsContainer}>
      {featuresArray.map(feature => (
        <FeaturesHighlightsCard
          title={feature.title}
          description={feature.description}
          img={feature.img}
          key={feature.title}
        />
      ))}
    </div>
  </div>
)

export default FeaturesHighlights
